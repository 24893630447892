import { createAsyncThunk } from '@reduxjs/toolkit';
import { StudentLoanApi } from 'api/StudentLoanApi';

export interface SetStudentLoanAssistanceScheduleData {
  applicationId: string;
  eventUri?: string;
  startDate?: string;
}

export const setStudentLoanAssistanceScheduleThunk = (api: StudentLoanApi) =>
  createAsyncThunk(
    'studentLoan/setAssistanceSchedule',
    async (data: SetStudentLoanAssistanceScheduleData, thunkApi) => {
      try {
        return await api.setStudentLoanAssistanceSchedule(data.applicationId, data.eventUri, data.startDate);
      } catch (error: any) {
        thunkApi.rejectWithValue(error);

        throw error;
      }
    },
  );
